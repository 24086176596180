import { useCallback } from 'react';
import { useOverloadedContentActions } from 'services/overloaded-content';
import loadable from 'components/dynamic-import';
import { OPEN_LOOTS_WRAPPER_PROPS } from './constants';
import { OpenCollectionLootsProps } from './OpenCollectionLoots';

const OpenCollectionLoot = loadable(() => import('./OpenCollectionLoots'));

export interface IUseOpenLoots {
  openLoots: (parms?: Pick<OpenCollectionLootsProps, 'cardsReceived'>) => void;
}

export const useOpenLoots = (): IUseOpenLoots => {
  const { openOverloadedContent } = useOverloadedContentActions();

  const handleOpenLoots = useCallback(
    (props: Pick<OpenCollectionLootsProps, 'cardsReceived'> = {}) => {
      openOverloadedContent({
        component: OpenCollectionLoot,
        componentProps: {
          ...props,
        },
        wrapperProps: OPEN_LOOTS_WRAPPER_PROPS,
      });
    },
    [openOverloadedContent],
  );

  return { openLoots: handleOpenLoots };
};
