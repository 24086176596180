import type { UsersSearchDataParams, UsersSearchParams } from './types';

const searchFiltersToParams = (filters: UsersSearchParams): UsersSearchDataParams => {
  const processedFilters: UsersSearchDataParams = {
    username: filters.username,
    limit: filters.limit,
  };
  if (filters.roles) {
    processedFilters.roles = filters.roles.join(',');
  }

  return processedFilters;
};

export default {
  searchFiltersToParams,
};
