import * as React from 'react';
import { StrictOmit } from '@streamloots/streamloots-types';
import { FixMe } from 'types/indexTS';
import memoize from 'memoize-one';
import ClassNames from '@streamloots/classnames';
import type { InputTextProps } from 'ui-library/form-elements/index';
import Icon from 'ui-library/icons';
import { DropdownBase } from './DropdownBase';
import { DropdownOption } from './types';
import theme from './form.scss';

const classNames = ClassNames(theme);

export interface DropdownProps extends StrictOmit<InputTextProps, 'onChange'> {
  placeholder?: string;
  options?: DropdownOption[];
  noMargin?: boolean;
  renderOption?: (option: FixMe) => React.ReactNode;
  optionsClassName?: string;
  wrapperClassName?: string;
  customIcon?: React.ReactNode;
  small?: boolean;
  big?: boolean;
  className?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  value?: string;
  labelClassName?: string;
  label?: string;
  required?: boolean;
  error?: string;
  id?: string;
  grey?: boolean;
}

export const Dropdown = ({
  options = [],
  className = '',
  big,
  optionsClassName,
  noMargin,
  placeholder,
  renderOption,
  small,
  wrapperClassName,
  onChange,
  disabled,
  value,
  error,
  grey,
  id,
  label,
  labelClassName,
  required,
}: DropdownProps): JSX.Element => {
  const getSelectedValue = memoize((valueDropdown, optionsDropdown) =>
    optionsDropdown.find(option => option.value === valueDropdown),
  );
  const [active, setActive] = React.useState(false);
  const [selectedOptionFocused, setselectedOptionFocused] = React.useState<number>(0);
  const innerRef = React.useRef<HTMLDivElement | null>(null);
  const inputRef = React.useRef<HTMLDivElement | null>(null);

  const closeOptions = () => {
    setActive(false);
  };

  const handleOptionClick = React.useCallback(
    (option: DropdownOption) => {
      onChange(option.value);
      closeOptions();
    },
    [onChange],
  );

  const handleChangeOptionFocus = (newIndex: number) => {
    setselectedOptionFocused(newIndex);
  };

  const openOptions = () => {
    if (disabled) {
      return;
    }
    setActive(true);
  };

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (innerRef.current?.contains(event.target)) {
        return;
      }

      if (inputRef.current?.contains(event.target)) {
        return;
      }

      closeOptions();
    };

    const handleKeyDown = event => {
      const code = event.which;

      if (!Array.isArray(options) || options.length === 0 || !active) {
        return false;
      }

      if (code === 27) {
        closeOptions();
        return false;
      }

      if (code === 13) {
        const index = selectedOptionFocused > -1 ? selectedOptionFocused : 0;
        const selectedResult = options[index];
        handleOptionClick(selectedResult);
        return false;
      }

      const lastIndex = options.length - 1;
      if (code === 38) {
        handleChangeOptionFocus(selectedOptionFocused > 0 ? selectedOptionFocused - 1 : lastIndex);
        return undefined;
      }

      if (code === 40) {
        handleChangeOptionFocus(selectedOptionFocused < lastIndex ? selectedOptionFocused + 1 : 0);
      }
      return undefined;
    };
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [active, handleOptionClick, options, selectedOptionFocused]);

  const renderSelected = () => {
    const selectedValue = getSelectedValue(value, options);

    if (!selectedValue) {
      return (
        <div className={theme.dropdown__placeholder} role="button" aria-labelledby={id} tabIndex={0}>
          {placeholder || ''}
        </div>
      );
    }

    return renderOption ? renderOption(selectedValue) : selectedValue.label;
  };

  return (
    <DropdownBase
      options={options}
      renderOption={renderOption}
      labelClassName={labelClassName}
      label={label}
      value={value}
      active={active}
      required={required}
      error={error}
      id={id}
      closeOptions={closeOptions}
      onOptionClick={handleOptionClick}
      optionsClassName={optionsClassName}
      selectedOptionFocused={selectedOptionFocused}
      onChangeOptionFocus={handleChangeOptionFocus}
      noMargin={noMargin}
      wrapperClassName={wrapperClassName}
      ref={innerRef}
    >
      <div
        id={id}
        data-testid={`${id}-options`}
        className={classNames({
          'dropdown__input': true,
          [className]: className,
          'dropdown__disabled': disabled,
          'dropdown__disabled--grey': disabled && grey,
          'dropdown__input--nomargin': noMargin,
          'dropdown__input--grey': grey,
          'dropdown__input--small': small,
          'dropdown__input--big': big,
        })}
        onClick={openOptions}
        ref={inputRef}
      >
        {renderSelected()}
        {grey ? (
          <Icon
            id="chevron-down"
            className={classNames({
              dropdown__small: small,
              dropdown__big: big,
            })}
            description="Open"
          />
        ) : (
          <svg
            className={theme.dropdown__arrow}
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fillRule="evenodd"
            fill="white"
          >
            <title>Open drop down</title>
            <path d="M10 0L5 5 0 0z" />
          </svg>
        )}
      </div>
    </DropdownBase>
  );
};
