import { forwardRef, useRef, ReactNode, useImperativeHandle } from 'react';
import ScrollbarPanel from 'components/scrollbar-panel';
import type { DropdownOption } from './types';
import DropdownOptionComponent from './DropdownOption';
import theme from './form.scss';

interface DropdownOptionProps {
  className?: string;
  options: DropdownOption[];
  onOptionClick: (option: DropdownOption) => void;
  selectedValue?: string;
  closeOptions: () => void;
  renderOption?: (option: DropdownOption) => ReactNode;
  selectedOptionFocused: number;
  onChangeOptionFocus: (newIndex: number) => void;
}

export const DropdownOptions = forwardRef<HTMLDivElement | undefined, DropdownOptionProps>(
  (
    {
      className = '',
      onOptionClick,
      options,
      selectedOptionFocused,
      onChangeOptionFocus,
      selectedValue,
      renderOption,
    }: DropdownOptionProps,
    forwardedRef,
  ): JSX.Element => {
    const innerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(forwardedRef, () => innerRef.current || undefined);

    const calculateMaxHeight = () => {
      if (!innerRef.current || typeof window === 'undefined') {
        return 500;
      }
      const windowHeight = window.innerHeight;
      const viewportOffset = innerRef.current.getBoundingClientRect();
      const topPosition = viewportOffset.top + 5;
      return windowHeight - topPosition;
    };

    const calculateHeight = () => {
      if (typeof window === 'undefined') {
        return '200px';
      }
      const windowHeight = window.innerHeight;
      const maxHeight = calculateMaxHeight();
      const calculatedHeight = windowHeight * 0.5;

      if (calculatedHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${calculatedHeight}px`;
    };

    return (
      <div ref={innerRef} className={`${theme.dropdown__options} ${className}`} role="menu">
        <ScrollbarPanel
          autoHeight
          autoHeightMin="35px"
          autoHeightMax={calculateHeight()}
          renderContent={({ scrollTo }) => (
            <>
              {options.map((option, index) => (
                <DropdownOptionComponent
                  renderOption={renderOption}
                  key={option.value}
                  option={option}
                  focus={index === selectedOptionFocused}
                  index={index}
                  onClick={onOptionClick}
                  selected={option.value === selectedValue}
                  onHover={onChangeOptionFocus}
                  scrollTo={scrollTo}
                />
              ))}
            </>
          )}
        />
      </div>
    );
  },
);
