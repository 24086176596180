import { useEffect, useState } from 'react';
import config from 'config';
import { Currency } from '@streamloots/streamloots-types';
import { getPaypalClientByCurrency } from 'utils/paypal';

interface UsePaypalClientIdProps {
  currency: Currency;
}

export interface UsePaypalClientIdOutput {
  clientId: string | null;
}
export const usePaypalClientId = ({ currency }: UsePaypalClientIdProps): UsePaypalClientIdOutput => {
  const [clientId, setClientId] = useState<string | null>(null);

  useEffect(() => {
    const client = getPaypalClientByCurrency(currency);

    setClientId(config.app.env === 'production' ? client.production : client.sandbox);
  }, [currency]);

  return { clientId };
};
