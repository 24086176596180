import { Role } from '@streamloots/streamloots-types';
import { useCallback, useEffect } from 'react';
import { useUserSearchActions, useUserSearchStateByComponentKey } from 'services/users-search';
import stringFormat from 'helpers/stringFormat';

export type UseSearchConfiguration = {
  roles?: Role[];
};

export const useSearch = (componentKey: string, { roles }: UseSearchConfiguration = {}) => {
  const searchUsersStatus = useUserSearchStateByComponentKey(componentKey);
  const { searchUsers, resetSearchUsers, initSearchUsers } = useUserSearchActions();

  useEffect(() => {
    if (!searchUsersStatus) {
      initSearchUsers(componentKey);
    }
  }, [componentKey, initSearchUsers, searchUsersStatus]);

  useEffect(() => {
    return () => {
      resetSearchUsers(componentKey);
    };
    // eslint-disable-next-line
  },[componentKey, resetSearchUsers]);

  const handleSearch = useCallback(
    (username: string) => {
      searchUsers(componentKey, stringFormat.removeWhiteSpaces(username), roles);
    },
    [searchUsers, componentKey, roles],
  );

  return {
    searchUsersStatus,
    searchUsers: handleSearch,
  };
};
