import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import type { BaseButtonProps } from 'ui-library/button';
import { ButtonIcon } from 'ui-library/button';
import { buildTwitterUrl } from './socialUrlBuilder';
import theme from './social.scss';

const classNames = ClassNames(theme);

type ShareOnTwitterButtonIconProps = BaseButtonProps & {
  className?: string;
  text: string;
  fill?: boolean;
  url?: string;
};

export const ShareOnTwitterButtonIcon = ({
  className = '',
  text,
  url,
  fill = false,
  variant,
  ...rest
}: ShareOnTwitterButtonIconProps): JSX.Element => {
  const classNamesButton = classNames({
    'twitter': true,
    'twitter--ghost': variant === 'ghost',
    'twitter--fill': fill,
    [className]: Boolean(className),
  });
  const twitterUrl = buildTwitterUrl(text, url);
  return (
    <ButtonIcon
      component="a"
      className={classNamesButton}
      href={twitterUrl}
      rel="noreferrer noopener"
      target="_blank"
      icon="twitter"
      {...rest}
    />
  );
};
