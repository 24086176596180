import type { CancelToken } from 'types/indexTS';
import actionCreator from 'helpers/actionCreator';
import type {
  UsersSearchResultsReceivedActionSuccess,
  UsersSearchResultsReceivedActionError,
  FetchingUsersResultsAction,
  UsersSearchParams,
  SearchUserActionResetAction,
  SearchUserActionInitializedAction,
} from './types';
import endpoints from '../endpoints';
import { UsersSearchActionTypes } from './actionTypes';
import { userSearchSelectors } from './selectors';
import normalizer from './normalizer';
import { USER_RESULTS_LIMIT } from './constants';

const userResultsReceived = ({ additionalData, data }): UsersSearchResultsReceivedActionSuccess =>
  actionCreator.createAction(UsersSearchActionTypes.RECEIVED, {
    searchKey: additionalData.searchKey,
    data: data.data,
    filters: additionalData.filters,
  });

const userResultsNotReceived = ({ additionalData, errorData }): UsersSearchResultsReceivedActionError =>
  actionCreator.createErrorAction(UsersSearchActionTypes.RECEIVED, errorData, {}, additionalData);

const fetchingUserResults = (
  searchKey: string,
  filters: UsersSearchParams,
  cancelToken: CancelToken,
): FetchingUsersResultsAction =>
  actionCreator.createAction(UsersSearchActionTypes.FETCHING, {
    searchKey,
    filters,
    cancelToken,
  });

const searchUsers = (
  searchKey: string,
  username: string,
  roles?: Array<string>,
  limit: number = USER_RESULTS_LIMIT,
) => {
  const filters = {
    username,
    roles,
    limit,
  };
  return actionCreator.createAsyncAction({
    additionalData: { searchKey, filters },
    endpoint: endpoints.USERS.SEARCH,
    errorBinding: userResultsNotReceived,
    loadingAction: (cancelToken: CancelToken) => fetchingUserResults(searchKey, filters, cancelToken),
    method: 'get',
    parameters: normalizer.searchFiltersToParams(filters),
    successBinding: userResultsReceived,
    cancelTokenSelector: state => userSearchSelectors.cancelToken(state, searchKey),
  });
};

const initSearchUsers = (searchKey: string): SearchUserActionInitializedAction =>
  actionCreator.createAction(UsersSearchActionTypes.INITIALIZED, { searchKey });

const resetSearchUsers = (searchKey: string): SearchUserActionResetAction =>
  actionCreator.createAction(UsersSearchActionTypes.RESET, { searchKey });

export const userSearchActions = {
  initSearchUsers,
  searchUsers,
  resetSearchUsers,
};
