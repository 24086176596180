import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

type ToggleProps = {
  children: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  duration?: number;
  ease?: string | Array<number>;
  initial?: boolean;
  animationKey: string;
};

const Toggle = ({
  animationKey,
  isOpen = false,
  children,
  className,
  duration = 0.3,
  ease = 'easeInOut',
  initial = false,
}: ToggleProps) => {
  // TODO: We need to fix not writing undefined as a key to enable the property as not optional log it until fix all cases
  if (animationKey === undefined) {
    console.warn("You're not passing animationKey to the <Toggle /> component");
  }
  return (
    <AnimatePresence initial={initial}>
      {isOpen && (
        <motion.div
          className={className}
          key={`toggle-content-${animationKey}`}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto', overflow: 'visible' },
            collapsed: { opacity: 0, height: 0, overflow: 'hidden' },
          }}
          transition={{ duration, ease }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Toggle;
