import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import type { DropdownOption as DropdownOptionType } from 'ui-library/form-elements';
import theme from './form.scss';

const classNames = ClassNames(theme);

type DropdownOptionProps = {
  option: DropdownOptionType;
  renderOption?: (option: DropdownOptionType) => React.ReactNode;
  focus?: boolean;
  index?: number;
  onClick: (option: DropdownOptionType) => void;
  onHover?: (index: number) => void;
  scrollTo: (offsetTop: number) => void;
  selected: boolean;
};

class DropdownOption extends React.PureComponent<DropdownOptionProps> {
  node?: HTMLDivElement;

  componentDidUpdate(prevProps) {
    const { focus, scrollTo } = this.props;

    if (focus && !prevProps.focus && this.node && typeof scrollTo === 'function') {
      scrollTo(this.node.offsetTop);
    }
  }

  setRef = (node: HTMLDivElement) => {
    this.node = node;
  };

  handleClick = () => {
    const { option, onClick } = this.props;
    onClick(option);
  };

  handleHover = () => {
    const { index, onHover } = this.props;
    if (typeof onHover === 'function' && typeof index !== 'undefined') {
      onHover(index);
    }
  };

  render() {
    const { renderOption, option, focus, selected } = this.props;
    return (
      <div
        ref={this.setRef}
        className={classNames({
          'dropdown__option': true,
          'dropdown__option-selected': selected,
          'dropdown__option-focus': focus,
        })}
        onMouseDown={this.handleClick}
        onMouseOver={this.handleHover}
        onFocus={this.handleHover}
        tabIndex={0}
        role="menuItem"
        aria-label={option.label}
      >
        {renderOption ? renderOption(option) : option.label}
      </div>
    );
  }
}

export default DropdownOption;
