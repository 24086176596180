import { createSelector } from 'reselect';
import type { CancelRequestInfo } from 'types/indexTS';
import type { UsersSearchState, SearchUsersStatus, UsersSearchStoreState } from './types';

const usersSearchState = (state): UsersSearchState => state.usersSearch;

const searchUsersStatus = createSelector(
  [usersSearchState, (_state, searchKey) => searchKey],
  (usersSearchStateInfo: UsersSearchState, searchKey: string): SearchUsersStatus => usersSearchStateInfo[searchKey],
);

const cancelToken = createSelector(
  [searchUsersStatus],
  (searchUsersStatusInfo: SearchUsersStatus): CancelRequestInfo | undefined => searchUsersStatusInfo.cancelToken,
);

export const userSearchSelectors = {
  cancelToken,
  searchUsersStatus,
};
