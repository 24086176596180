import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { SearchUsersStatus, InitSearchUsers, SearchUsers, ResetSearchUsers } from './types';
import { userSearchSelectors } from './selectors';
import { userSearchActions } from './actions';

export const useUserSearchStateByComponentKey = (componentKey: string): SearchUsersStatus => {
  return useSelector(state => userSearchSelectors.searchUsersStatus(state, componentKey));
};

export interface UseUserSearchActions {
  initSearchUsers: InitSearchUsers;
  searchUsers: SearchUsers;
  resetSearchUsers: ResetSearchUsers;
}

export const useUserSearchActions = (): UseUserSearchActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      initSearchUsers: componentKey => dispatch(userSearchActions.initSearchUsers(componentKey)),
      searchUsers: (componentKey, username, roles, limit) =>
        dispatch(userSearchActions.searchUsers(componentKey, username, roles, limit)),
      resetSearchUsers: componentKey => dispatch(userSearchActions.resetSearchUsers(componentKey)),
    }),
    [dispatch],
  );
};
