import { forwardRef, ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import type { DropdownOption } from './types';
import { DropdownOptions } from './DropdownOptions';
import { Label } from './Label';
import FormElementAdditionalInfo from './FormElementAdditionalInfo';
import theme from './form.scss';

const classNames = ClassNames(theme);

export interface DropdownBaseProps {
  active?: boolean;
  children: ReactNode;
  closeOptions: () => void;
  error?: string;
  id?: string;
  label?: ReactNode;
  labelClassName?: string;
  noMargin?: boolean;
  options: Array<DropdownOption>;
  optionsClassName?: string;
  wrapperClassName?: string;
  selectedOptionFocused: number;
  onChangeOptionFocus: (newIndex: number) => void;
  onOptionClick: (option: DropdownOption) => void;
  renderOption?: (option: DropdownOption) => ReactNode;
  required?: boolean;
  value?: string;
}

export const DropdownBase = forwardRef<HTMLDivElement, DropdownBaseProps>(
  (
    {
      active,
      children,
      id,
      label,
      labelClassName = '',
      required,
      options,
      optionsClassName = '',
      noMargin,
      renderOption,
      value,
      error,
      closeOptions,
      onOptionClick,
      selectedOptionFocused,
      onChangeOptionFocus,
      wrapperClassName = '',
    }: DropdownBaseProps,
    innerRef,
  ): JSX.Element => {
    return (
      <>
        {label && (
          <Label className={labelClassName} id={id} error={error} required={required}>
            {label}
          </Label>
        )}
        <div
          data-testid={id}
          className={classNames({
            'dropdown': true,
            'dropdown--nomargin': noMargin,
            [wrapperClassName]: Boolean(wrapperClassName),
          })}
        >
          {children}
          {active && options.length > 0 && (
            <div data-testid="dropdown-options" className={classNames('dropdown__options__wrapper')}>
              <DropdownOptions
                onOptionClick={onOptionClick}
                closeOptions={closeOptions}
                renderOption={renderOption}
                className={optionsClassName}
                options={options}
                selectedValue={value}
                selectedOptionFocused={selectedOptionFocused}
                onChangeOptionFocus={onChangeOptionFocus}
                ref={innerRef}
              />
            </div>
          )}
          <FormElementAdditionalInfo error={error} />
        </div>
      </>
    );
  },
);
