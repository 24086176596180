import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { InputText, InputTextProps } from 'ui-library/form-elements';
import Icon from 'ui-library/icons';
import type { SearchInputConfiguration } from './types';
import theme from './autocomplete-async.scss';

const classNames = ClassNames(theme);

export interface SearchInputProps
  extends Pick<
      InputTextProps,
      | 'label'
      | 'id'
      | 'className'
      | 'placeholder'
      | 'onFocus'
      | 'onBlur'
      | 'onKeyDown'
      | 'error'
      | 'disabled'
      | 'name'
      | 'required'
    >,
    SearchInputConfiguration {
  onChange: (string) => void;
  value: string;
}

// TODO use ui-library one
class SearchInput extends React.Component<SearchInputProps> {
  checkerTimeout?: number;

  restoreTimeout = (newValue: string) => {
    const { allowEmpty, value, search } = this.props;
    clearTimeout(this.checkerTimeout);
    this.checkerTimeout = window.setTimeout(() => {
      if ((!newValue && !allowEmpty) || value === newValue) {
        return;
      }
      search(newValue);
    }, 300);
  };

  handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    this.restoreTimeout(evt.currentTarget.value);
    onChange(evt.currentTarget.value);
  };

  render() {
    const {
      id,
      label,
      placeholder,
      onBlur,
      onFocus,
      onKeyDown,
      className = '',
      inputClassName,
      searchIcon,
      error,
      value,
      disabled,
      name,
    } = this.props;
    return (
      <div
        className={classNames({
          [className]: Boolean(className),
          'autocomplete-async--icon': searchIcon,
        })}
      >
        <InputText
          label={label}
          id={id}
          className={inputClassName}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={this.handleChange}
          autoComplete="off"
          value={value}
          error={error}
          disabled={disabled}
          name={name}
        />
        {searchIcon && <Icon id="search" />}
      </div>
    );
  }
}

export { SearchInput };
