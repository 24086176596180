import { Currency } from '@streamloots/streamloots-types';
import config from 'config';

export interface PaypalClient {
  sandbox: string;
  production: string;
}

export const getPaypalClientByCurrency = (currency: string): PaypalClient => {
  if (currency !== Currency.EUR) {
    return {
      sandbox: config.paypalUs.sandboxClient,
      production: config.paypalUs.productionClient,
    };
  }

  return {
    sandbox: config.paypal.sandboxClient,
    production: config.paypal.productionClient,
  };
};
