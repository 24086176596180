import { useState } from 'react';
import ClassNames from '@streamloots/classnames';
import type { User } from 'model/indexTS';
import { withTranslation, TranslateInterface } from 'utils/translation';
import type { OnResultClick, ResultItem } from 'components/autocomplete-async';
import { SearchInput, Results } from 'components/autocomplete-async';
import { useSearch } from 'components/user-search/useSearch';
import theme from './user-search.scss';

const classNames = ClassNames(theme);

interface UserSearchProps extends TranslateInterface {
  className?: string;
  label?: string;
  onUserClick: OnResultClick;
  selectedValue?: string;
  searchKey: string;
  id?: string;
  /*

        label={t('selectUser')}
        searchKey="purchaseAsGift"
        onUserClick={onSelectUser}
        selectedValue={selectedUser && selectedUser._id}
   */
}

const normalizeResult = (user: User): ResultItem => {
  const {
    _id,
    profile: { avatarImageUrl, username },
  } = user;

  return {
    _id,
    imageUrl: avatarImageUrl,
    name: username,
  };
};

const UserSearchInternal = (props: UserSearchProps) => {
  const { className, onUserClick, label, searchKey, selectedValue = '', id, t } = props;
  const [username, setUsername] = useState('');
  const { searchUsersStatus, searchUsers } = useSearch(searchKey);

  const handleChange = (value: string) => {
    setUsername(value);
  };
  if (!searchUsersStatus) {
    return null;
  }

  const { error, isLoading, results } = searchUsersStatus;

  return (
    <div className={className}>
      <SearchInput
        search={searchUsers}
        label={label}
        placeholder={t('common:userSearch.searchUserByUsername')}
        searchIcon={false}
        value={username}
        onChange={handleChange}
        id={id}
      />
      <Results
        autoHeight
        autoHeightMin="150px"
        autoHeightMax="150px"
        isLoading={isLoading}
        className={classNames('user-search__results')}
        normalizeResult={normalizeResult}
        onResultClick={onUserClick}
        results={results}
        resultsError={error?.message}
        selectedValue={selectedValue}
        t={t}
        searchString={username}
      />
    </div>
  );
};

export const UserSearch = withTranslation('common')(UserSearchInternal);
