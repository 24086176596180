import urlUtils from 'utils/urlUtils';

export function buildTwitterUrl(text: string, url = ''): string {
  const queryParams = urlUtils.paramsToQueryStream({
    text,
    url,
  });
  return `https://twitter.com/intent/tweet${queryParams}`;
}

export function buildFacebookUrl(url: string): string {
  return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
}
